<template>
  <div class="container">
    <h5 v-if="isMasterPlayer" class="white-text">You are the master player</h5>
    <h5 v-else class="white-text">Connecting to Spotify and configuring as master</h5>
    <nowplaying />
    <playlist />
  </div>
</template>

<script>
import nowplaying from "@/components/NowPlaying";
import { setupLocalPlayer } from "../systems/Spotify";
import store from "@/systems/Store"
import playlist from "@/components/Playlist"
import Search from "@/systems/Search";

export default {
  name: "SbMaster",
  components: {
    nowplaying,
    playlist
  },
  computed:{
      isMasterPlayer() {
      return this.$store.getters.isMasterPlayer;
    }
  },
  mounted() {
    setupLocalPlayer();
    Search.dummy();
    //MasterPlayer().playNextTrack();
    setTimeout(function() {
      //_this.$router.push('/');
      store.commit("SetMasterPlayer", true);
    }, 3000);
  }
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>